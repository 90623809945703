
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: fit-content;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #ebc11bbe;}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {background-color: #3e8e41;}

  .overlay{
    /* background-color: rgba(0, 0, 0, 0.363); */
    padding: 100px 0 100px;
  }

  .criteria-form .form-check-input:checked{
    background-color: #004B07;
    border-color: #004B07;
  }

  .covid-custom-card{
    position: relative;
  }


  .flower-bg , .flower-bg img{
    position: relative;
    z-index: 2;
  }
  .flower-bg img{
   width: 100%;
    height: auto;
  }
  .flower-bg::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #06a813, #004B07);
    filter: blur(160px);
    -webkit-filter: blur(121px);
    border: 100px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(45deg, #06a813, #004B07);
    outline: 100px solid #06a813d4;
  }

  
  
  .text-18{
    font-size: 18px;
  }
  .welcome-text{
    z-index: 3;
  }
  /* .welcome-text::before{
    content: "";
    position: absolute;
    background: #06A813;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    filter: blur(92px);
    opacity: 0.5;
    outline: 100px solid #06A813;
  } */
  .custom-.btn-lg {
    --bs-btn-padding-y: 0.8rem;
    --bs-btn-padding-x: 4rem;
    --bs-btn-font-size: 3.25rem;
    --bs-btn-border-radius: var(--bs-border-radius-lg);
}
.btn-herb-blue{

  border: 1px solid #000;
  padding: 11px 48px;
  border-radius: 8px;
  transition: ease-in;
  color:#000;
}
.btn-herb-blue:hover{
  transition: 0.5s ease-out;
  background-color: #f1c40f;
  color:#fff;
}
.intor-gradinet-h1{
  font-size: 60px;
    background: -webkit-linear-gradient(272deg,#000, #000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.welcome-text p{
  color:#000
}
@media only screen and (max-width: 600px) {
  .intor-gradinet-h1{
    font-size: 40px;
  }
}

.col-x-gap{
  column-gap: 5px;
}
.drop-btn{
  background: transparent;
  border: 2px solid white;
  border-radius: 52px;
  padding: 13px 20px;


}
.drop-btn:hover{
  background: none;

  border: 2px solid white;
}
.drop-toggle-menu{

  top: 4px !important;
  left: 7px !important;

}